import {Inject, Injectable} from '@angular/core';
import {AppConfigService} from 'app/app/config';

@Injectable({
  providedIn: 'root',
})
export class WalkmeService {
  constructor(@Inject(AppConfigService) private readonly appConfig: MsAppConfig) {}

  private readonly scriptSourceTest =
    'https://cdn.walkme.com/users/34912a04b6fe4252a0ab979dc9626582/test/walkme_private_34912a04b6fe4252a0ab979dc9626582_https.js';
  private readonly scriptIntegrityTest = 'sha256-ge+8nuQ7bJ3HOvafk2/7kXfKGGTcuHQ90l6zOot2Zjo=';
  private readonly scriptSourceProd =
    'https://cdn.walkme.com/users/34912a04b6fe4252a0ab979dc9626582/walkme_private_34912a04b6fe4252a0ab979dc9626582_https.js';
  private readonly scriptIntegrityProd = 'sha256-jyL+aci0q9yLwRas5Y1NTObuSJnKJuLiX9WCnwQhxeY=';

  load(): void {
    const isWalkMeLoaded = !!document.getElementById('ms-walkme');
    const shouldLoadWalkMe = !isWalkMeLoaded && this.appConfig.walkMe?.enabled;
    if (shouldLoadWalkMe) {
      // eslint-disable-next-line no-underscore-dangle
      window._walkmeConfig = {smartLoad: false};
      const walkme = document.createElement('script');
      walkme.id = 'ms-walkme';
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = this.appConfig.walkMe.useProdEnv ? this.scriptSourceProd : this.scriptSourceTest;
      walkme.integrity = this.appConfig.walkMe.useProdEnv ? this.scriptIntegrityProd : this.scriptIntegrityTest;
      walkme.crossOrigin = 'anonymous';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(walkme, s);
    }
  }

  unload(): void {
    // eslint-disable-next-line no-underscore-dangle
    if (window._walkMe?.removeWalkMe) {
      // eslint-disable-next-line no-underscore-dangle
      window._walkMe.removeWalkMe();
    }
  }
}
