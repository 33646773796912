import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {patch} from '@ngxs/store/operators';
import {GetTcSecondaryCodes, InvalidateTcSecondaryCodes} from './secondary-codes.action';
import {SecondaryCodesApiService} from '@matchsource/api/secondary-codes';
import {SecondaryCodeModel} from '@matchsource/models/secondary-codes';

export interface TcSecondaryCodesStateModel {
  loading: boolean;

  loaded: boolean;

  secondaryCodes: SecondaryCodeModel[];

  error: any;
}

export interface SecondaryCodeStateModel {
  [tcId: string]: TcSecondaryCodesStateModel;
}

const defaultTcSecondaryCodesState = (overrides = {}): TcSecondaryCodesStateModel => ({
  loading: false,
  loaded: false,
  secondaryCodes: [],
  error: undefined,
  ...overrides,
});

@State<SecondaryCodeStateModel>({
  name: 'secondaryCodeEntities',
  defaults: {},
})
@Injectable()
export class SecondaryCodesState {
  @Selector([SecondaryCodesState])
  static loading(state: SecondaryCodeStateModel) {
    return (tcId: MsApp.Guid) => {
      return !(tcId in state) || state[tcId].loading;
    };
  }

  @Selector([SecondaryCodesState])
  static tcSecondaryCodes(state: SecondaryCodeStateModel) {
    return (tcId: MsApp.Guid) => {
      return tcId in state ? state[tcId].secondaryCodes : [];
    };
  }

  constructor(private readonly secondaryCodesApi: SecondaryCodesApiService) {}

  @Action(GetTcSecondaryCodes)
  getTcSecondaryCodes(ctx: StateContext<SecondaryCodeStateModel>, {tcId}: GetTcSecondaryCodes) {
    const state = ctx.getState();
    const tcState = tcId in state ? state[tcId] : null;

    if (tcState && (tcState.loaded || tcState.loading)) {
      return;
    }

    ctx.setState(
      patch({
        [tcId]: defaultTcSecondaryCodesState({loading: true}),
      })
    );

    return this.secondaryCodesApi.getCodes(tcId).pipe(
      tap(secondaryCodes => {
        ctx.setState(
          patch({
            [tcId]: patch<TcSecondaryCodesStateModel>({
              loading: false,
              loaded: true,
              secondaryCodes,
            }),
          })
        );
      })
    );
  }

  @Action(InvalidateTcSecondaryCodes)
  invalidateTcSecondaryCodes(ctx: StateContext<SecondaryCodeStateModel>, {tcId}: InvalidateTcSecondaryCodes) {
    if (!(tcId in ctx.getState())) {
      return;
    }
    ctx.setState(
      patch({
        [tcId]: patch<TcSecondaryCodesStateModel>({
          loaded: false,
        }),
      })
    );
  }
}
