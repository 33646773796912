import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {compileTemplate, trim} from '@matchsource/utils';

export const MIN_ID_LENGTH = 4;
export const MIN_NAME_LENGTH = 2;
export const ADULT_SEARCH_LENGTH = 13;

const NAME_REGEXP_TPL = '^([^,]{{$MIN_LENGTH},}|[^ ,]{{$MIN_LENGTH},}\\s*,\\s*[^ ,].*)$';

const IS_ID_REGEXP = /^[\d-]+$/;
const IS_VALID_NAME_REGEXP = new RegExp(compileTemplate(NAME_REGEXP_TPL, {MIN_LENGTH: MIN_NAME_LENGTH}));
const IS_NAME_REGEXP = new RegExp(compileTemplate(NAME_REGEXP_TPL, {MIN_LENGTH: 1}));

export const isValidPatientId = (input: string) =>
  !IS_ID_REGEXP.test(input) || input.replace(/-/g, '').length >= MIN_ID_LENGTH;
export const isAdultSearchLength = (input: string) => input.length === ADULT_SEARCH_LENGTH;
export const isInvalidPatientEntry = (input: string) =>
  // don't support empty string and only dashes
  /^-*$/.test(input) ||
  // don't support multi-dashes in non-numeric string
  (!/[\d]/.test(input) && /-{2,}/.test(input)) ||
  // don't support string which contains only one comma and ends with it
  /^[^,]*,$/.test(input) ||
  // it is not ID and name
  (!IS_ID_REGEXP && !IS_NAME_REGEXP.test(input));
export const isValidPatientName = (input: string) => IS_NAME_REGEXP.test(input) && IS_VALID_NAME_REGEXP.test(input);

export const createSearchPatientValidator = (
  validationFn: (input: string) => boolean,
  errorCode: string
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isValid = validationFn(trim(value));

    return !isValid ? {[errorCode]: {value}} : null;
  };
};
