import {TargetState, UIRouter} from '@uirouter/core';
import {EnvironmentInjector, inject, Injector} from '@angular/core';
import {StateObject, StatesModule} from '@uirouter/angular';
import {hasOwnProperty} from '@matchsource/utils';
import {Transition} from '@uirouter/core/lib/transition/transition';
import {LOGIN_STATE_NAME, LOGOUT_STATE_NAME} from './routes';
import {AppRoutes} from 'app/app/declarations';
import {AuthService} from 'app/core/services/auth.service';
import {firstValueFrom} from 'rxjs';
import isEqual from 'lodash-es/isEqual';
import startCase from 'lodash-es/startCase';
import {
  getCompareParams,
  getExtraParams,
  getGroupType,
  getItemRoute,
  shouldItemBeSaved,
  NavigationHistoryService,
} from '@matchsource/navigation-history';
import {Title} from '@angular/platform-browser';
import {ResetService} from 'app/ngxs-reset/services/reset.service';
import {ToastService} from '@matchsource/nmdp-ui';
import {environment} from '../../environments/environment';
import {UserService} from '@matchsource/core';
import {SpinnerService} from '@matchsource/spinner';
import {PermissionsService} from '@matchsource/store/permissions';
import {LAST_VISITED_ROUTE} from '@matchsource/shared/navigation';

const promisify = (val: any): Promise<any> => (val instanceof Promise ? val : Promise.resolve(val));

const findStateParamOwners = (state: StateObject, param: string) => {
  const ownerStates: StateObject[] = [];

  if (!state || !state.data || !state.data[param]) {
    return ownerStates;
  }

  let currentState = state;

  while (currentState !== null && currentState.data) {
    if (hasOwnProperty(currentState.data, param)) {
      ownerStates.push(currentState);
    }

    currentState = currentState.parent;
  }

  return ownerStates;
};

function handlePermissions(transition: Transition) {
  const permissionsService = inject(PermissionsService);
  let currentState = transition.$to();
  const permissions = [];

  while (currentState !== null) {
    if ((currentState as any).protected || (currentState.data && hasOwnProperty(currentState.data, 'protected'))) {
      permissions.push(...currentState.data.permissions);
    }

    currentState = currentState.parent;
  }

  if (permissions.length && !permissionsService.hasPermissions(permissions)) {
    const stateService = transition.router.stateService;
    const toast = inject(ToastService);

    toast.error({
      message: 'Trying to access unauthorized data. Redirected to the main page.',
    });
    return stateService.target(AppRoutes.Core, {}, {location: 'replace'});
  }
}

async function handleUnauthenticatedUsers(transition: Transition) {
  const userService = inject(UserService);
  const authService = inject(AuthService);

  const toState = transition.$to();
  if (userService.isLoggedIn() === false && (await authService.isSessionAlive())) {
    await firstValueFrom(authService.restoreSession());
    await firstValueFrom(authService.login());
  }

  const stateService = transition.router.stateService;
  // when the user is not logged in
  if (userService.isLoggedIn() === false) {
    if (!toState.data || toState.data.requireAuthorization !== false) {
      // User isn’t allowed so redirect to the login screen
      // pass the state that the user tried to open
      const returnState = LOGOUT_STATE_NAME === toState.name ? null : toState.name;
      return stateService.target(LOGIN_STATE_NAME, {returnState, params: transition.params('to')});
    }
  } else if (toState.name === LOGIN_STATE_NAME) {
    return stateService.target(AppRoutes.Core, {}, {location: 'replace'});
  }
}

function handleTransitionBefore(transition: Transition) {
  const toState = transition.$to();
  const transitionHandlers = [];

  if (toState.data && toState.data.canActivateBefore) {
    transitionHandlers.push(toState.data.canActivateBefore);
  }

  return transitionHandlers.reduce(
    (result, handler) =>
      result.then((isValid: any) => {
        if (isValid === false || isValid instanceof TargetState) {
          return isValid;
        }

        return promisify(handler(transition));
      }),
    Promise.resolve(true)
  );
}

function handleTransitionStart(transition: Transition) {
  const spinner = inject(SpinnerService);
  const toState = transition.$to();
  const fromState = transition.$from();
  const transitionHandlers = [];
  const toParams = transition.params('to');
  const fromParams = transition.params('from');

  if (fromState && fromState.data && fromState.data.canDeactivate) {
    if (!toParams.$skipGuards) {
      const deactivation = findStateParamOwners(fromState, 'canDeactivate')
        .filter(ownerState => toState.name.indexOf(ownerState.name) !== 0 || !isEqual(toParams, fromParams))
        .map(state => state.data.canDeactivate);
      if (deactivation.length > 0) {
        transitionHandlers.push(...deactivation);
      }
    }
  }

  if (toState.data && toState.data.canActivate) {
    transitionHandlers.push(toState.data.canActivate);
  }

  if (toState.data && toState.data.showSpinner) {
    transitionHandlers.push(() => {
      spinner.show();
    });
  }

  return transitionHandlers.reduce(
    (result, handler) =>
      result.then((isValid: any) => {
        if (isValid === false) {
          return isValid;
        }

        return promisify(handler(transition));
      }),
    Promise.resolve(true)
  );
}

function handleTransitionSuccess(transition: Transition) {
  const spinner = inject(SpinnerService);
  const resetService = inject(ResetService);
  const toState = transition.$to();

  if (toState.data?.showSpinner) {
    spinner.hide();
  }

  if (toState.data?.cleanupMemory) {
    resetService.reset();
  }
}

function handlePageTitle(transition: Transition) {
  const userService = inject(UserService);
  const titleService = inject(Title);
  const toState = transition.$to();
  const userRole = userService.isLoggedIn() ? `${userService.getUserRoles().join(', ')} -` : '';
  const stateTitle = toState.data?.alias ? toState.data.alias : startCase(toState.name);

  titleService.setTitle(`MatchSource - ${userRole} ${stateTitle}`);
}

async function handlePageHistory(transition: Transition) {
  const targetState = transition.$to();
  const navigationHistoryService = inject(NavigationHistoryService);
  const navParams = targetState.data?.navigation || {};
  const isItemForNavigationHistory = shouldItemBeSaved(targetState);

  if (!isItemForNavigationHistory) {
    return;
  }

  if (navParams.skip) {
    if (typeof navParams.skip !== 'function') {
      return;
    }

    if (navParams.skip(transition)) {
      return;
    }
  }

  const params = transition.params('to');
  const groupType = getGroupType(targetState, transition);
  const route = getItemRoute(targetState);
  const paramsForComparison = getCompareParams(targetState);
  const extraParams = await getExtraParams(targetState, transition);

  navigationHistoryService.saveNavigationItem(
    {
      route,
      originalRoute: targetState.name,
      routeParams: params,
      itemType: null,
      extraParams,
      groupType,
    },
    paramsForComparison
  );
}

function handleTransitionError(transition: Transition) {
  const spinner = inject(SpinnerService);
  const resetService = inject(ResetService);
  const toState = transition.$to();

  if (toState.data?.showSpinner) {
    spinner.hide();
  }

  if (toState.data?.cleanupMemory) {
    resetService.reset();
  }
}

function handleForbidden(transition: Transition) {
  const toast = inject(ToastService);
  const stateService = transition.router.stateService;

  transition.promise
    .then(() => {
      toast.error({
        message: 'Trying to access unauthorized data. Redirected to the main page.',
      });
    })
    .catch(transitionError => {
      if (transitionError.detail?.status === 403) {
        // just redirect to dashboard if user has no permission to get any data
        toast.error({
          message: 'Trying to access unauthorized data. Redirected to the main page.',
        });
        stateService.go(AppRoutes.Core, {}, {location: 'replace'});
      }
    });
}

export function configureRouter(uiRouter: UIRouter, injector: Injector, _: StatesModule) {
  // We need this call to run router after all APP_INITIALIZER handlers
  uiRouter.urlService.deferIntercept();
  if (environment.routerPlugins) {
    environment.routerPlugins.forEach(plugin => uiRouter.plugin(plugin));
  }

  const transitionService = uiRouter.transitionService;
  const environmentInjector = injector.get(EnvironmentInjector);

  const runInContext = <ReturnT>(fn: () => ReturnT): ReturnT => environmentInjector.runInContext(fn);

  async function onBeforeTransitionStart(transition: Transition) {
    localStorage.removeItem(LAST_VISITED_ROUTE);
    const handlers = [
      () => runInContext(() => handleUnauthenticatedUsers(transition)),
      () => runInContext(() => handlePermissions(transition)),
      () => handleTransitionBefore(transition),
    ];

    return handlers.reduce<Promise<boolean | TargetState>>((res, handler) => {
      return res.then(ret => {
        if (ret === false || ret instanceof TargetState) {
          return ret;
        }

        return promisify(handler());
      });
    }, Promise.resolve(true));
  }

  function onTransitionStart(transition: Transition) {
    return handleTransitionStart(transition);
  }

  function onTransitionSuccess(transition: Transition) {
    const lastVisitedRoute = localStorage.getItem(LAST_VISITED_ROUTE);

    if (lastVisitedRoute) {
      // history.back() method is needed to remove from the history the duplicate route created by [msHistoryBackKeeper] to save the history during the Leave or stay popup
      window.history.back();
    }

    handleTransitionSuccess(transition);
    handlePageTitle(transition);
    handlePageHistory(transition);
  }

  function onTransitionError(transition: Transition) {
    handleTransitionError(transition);
    handleForbidden(transition);
  }

  uiRouter.urlService.rules.otherwise({state: 'login'});

  transitionService.onBefore({}, (transition: Transition) => runInContext(() => onBeforeTransitionStart(transition)));
  transitionService.onStart({}, (transition: Transition) => runInContext(() => onTransitionStart(transition)));
  transitionService.onSuccess({}, (transition: Transition) => runInContext(() => onTransitionSuccess(transition)));
  transitionService.onError({}, (transition: Transition) => runInContext(() => onTransitionError(transition)));

  (() => {
    const onSuccessFirstUnsubscribe = transitionService.onSuccess({}, () => {
      const preloadEl = document.getElementById('msPreload');
      document.body.classList.remove('app-initializing');
      preloadEl.parentNode.removeChild(preloadEl);

      onSuccessFirstUnsubscribe();
    });
  })();
}
