import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, map, mapTo, pairwise} from 'rxjs/operators';

enum IdleStatus {
  Active,
  Paused,
  Inactive,
}

@Injectable({
  providedIn: 'root',
})
export class IdleService implements OnDestroy {
  readonly interrupted: Subject<void> = new Subject();

  private readonly status$: BehaviorSubject<IdleStatus> = new BehaviorSubject<IdleStatus>(IdleStatus.Active);

  readonly active$: Observable<boolean> = this.status$.pipe(map(status => status === IdleStatus.Active));

  readonly pause$: Observable<boolean> = this.status$.pipe(map(status => status === IdleStatus.Paused));

  readonly reactivated$: Observable<void> = this.status$.pipe(
    pairwise(),
    filter(([oldStatus, newStatus]) => oldStatus !== IdleStatus.Active && newStatus === IdleStatus.Active),
    mapTo(undefined)
  );

  get status(): IdleStatus {
    return this.status$.value;
  }

  get isActive(): boolean {
    return this.status === IdleStatus.Active;
  }

  pause(): void {
    if (this.status === IdleStatus.Active) {
      this.changeStatus(IdleStatus.Paused);
    }
  }

  resume(): void {
    if (this.status === IdleStatus.Paused) {
      this.changeStatus(IdleStatus.Active);
    }
  }

  enable(enabled: boolean): void {
    const newStatus: IdleStatus = enabled ? IdleStatus.Active : IdleStatus.Inactive;

    this.changeStatus(newStatus);
  }

  private changeStatus(status: IdleStatus): void {
    if (this.status !== status) {
      this.status$.next(status);
    }
  }

  interrupt() {
    this.interrupted.next();
  }

  ngOnDestroy(): void {
    this.interrupted.complete();
    this.status$.complete();
  }
}
