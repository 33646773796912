import {NgModule} from '@angular/core';
import {ROUTES} from './routes';
import {FetchingDataModule} from 'app/shared/fetching-data.module';
import {PatientSharedModule} from 'app/shared/patient/patient-shared.module';
import {NgxsModule} from '@ngxs/store';
import {PatientFormState} from './store/patient-form.state';
import {UIRouterModule} from '@uirouter/angular';

@NgModule({
  imports: [
    UIRouterModule.forChild({states: ROUTES}),
    FetchingDataModule,
    PatientSharedModule,
    NgxsModule.forFeature([PatientFormState]),
  ],
})
export class PatientRootModule {}
