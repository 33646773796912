import {ENVIRONMENT_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {ResetService} from './services/reset.service';

export function noop() {
  return (): void => undefined;
}

@NgModule()
export class NgxsResetModule {
  static forRoot(): ModuleWithProviders<NgxsResetModule> {
    return {
      ngModule: NgxsResetModule,
      providers: [
        ResetService,
        {
          provide: ENVIRONMENT_INITIALIZER,
          useFactory: noop,
          deps: [ResetService],
          multi: true,
        },
      ],
    };
  }
}
