import {NgFor} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {TranslocoDirective} from '@jsverse/transloco';
import {RolePipe} from 'app/shared/pipes/role.pipe';
import {RadioComponent} from '@matchsource/nmdp-ui';
import {Subject, distinctUntilChanged, takeUntil} from 'rxjs';

@Component({
  selector: 'ms-switch-roles',
  templateUrl: './switch-roles.component.html',
  styleUrl: './switch-roles.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgFor, TranslocoDirective, RadioComponent, RolePipe],
})
export class SwitchRolesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userRoles: string[];
  @Input() activeRole: string;

  @Output() switchRole = new EventEmitter<string>();

  readonly role = new UntypedFormControl();

  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.role.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(value => {
      this.switchRole.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('activeRole' in changes) {
      this.role.setValue(this.activeRole);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
