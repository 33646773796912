import {Action, Selector, State, StateContext} from '@ngxs/store';
import {TransplantTimelineApiService} from '@matchsource/api/transplant-timeline';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {GetTransplantTimeline} from './transplant-timeline.action';
import {of} from 'rxjs';
import {TransplantTimelineModel} from '@matchsource/models/nomenclature';

export type TransplantTimelineStateModel = EntitiesStateModel<TransplantTimelineModel>;

@State<TransplantTimelineStateModel>({
  name: 'transplantTimeline',
  defaults: defaultEntitiesState<TransplantTimelineModel>(),
})
@Injectable()
export class TransplantTimelineState {
  constructor(private readonly transplantTimelineApi: TransplantTimelineApiService) {}

  @Selector([TransplantTimelineState])
  static byCode(state: TransplantTimelineStateModel) {
    return (code: string) => state.entities[code];
  }

  @Action(GetTransplantTimeline)
  getAll(ctx: StateContext<TransplantTimelineStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.transplantTimelineApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(
            addOrReplaceEntities<TransplantTimelineModel>('code', entities),
            loadedEntities(true),
            loadingEntities(false)
          )
        )
      )
    );
  }
}
