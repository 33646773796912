import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AdvancedLookupPatientsClear} from 'app/features/advanced-patient-lookup/store/advanced-patient-lookup.actions';

@Injectable({
  providedIn: 'root',
})
export class AdvancedPatientLookupHelperService {
  constructor(private readonly store: Store) {}

  public clear(): void {
    this.store.dispatch(new AdvancedLookupPatientsClear());
  }
}
