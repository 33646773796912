import {MapTo} from '@matchsource/api-utils';
import {ShortAlleleDto} from '.';
import {ShortAlleleAlleleModel, ShortAlleleModel, ShortAlleleGGroupModel} from '@matchsource/models/allele';

export const shortAlleleModelFromDto: MapTo<ShortAlleleDto, ShortAlleleModel> = ({
  shortAlleles: alleles = null,
  gGroups = [],
}: ShortAlleleDto): ShortAlleleModel => ({
  alleles: alleles as ShortAlleleAlleleModel[],
  gGroups: gGroups as ShortAlleleGGroupModel[],
});
