import {Action, Selector, State, StateContext} from '@ngxs/store';
import {LanguagesApiService} from '@matchsource/api/languages';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {GetLanguages} from './languages.action';
import {of} from 'rxjs';

import {Nomenclature} from '@matchsource/models/nomenclature';

export type LanguagesStateModel = EntitiesStateModel<Nomenclature>;

@State<LanguagesStateModel>({
  name: 'languages',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class LanguagesState {
  constructor(private readonly languagesApi: LanguagesApiService) {}

  @Selector([LanguagesState])
  static byCode(state: LanguagesStateModel) {
    return (code: string) => state.entities[code];
  }

  @Action(GetLanguages)
  getAll(ctx: StateContext<LanguagesStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.languagesApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
