import {Action, State, StateContext} from '@ngxs/store';
import {PreferredProductsApiService} from '@matchsource/api/preferred-products';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {GetPreferredProducts} from './preferred-products.action';
import {of} from 'rxjs';

import {Nomenclature} from '@matchsource/models/nomenclature';

export type PreferredProductsStateModel = EntitiesStateModel<Nomenclature>;

@State<PreferredProductsStateModel>({
  name: 'preferredProducts',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class PreferredProductsState {
  constructor(private readonly preferredProductsApi: PreferredProductsApiService) {}

  @Action(GetPreferredProducts)
  getAll(ctx: StateContext<PreferredProductsStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.preferredProductsApi.get().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
