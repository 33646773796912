<div class="ms-dropdown ms-top-header-search-type" test-id="toggle-dropdown">
  <a class="search-options-link dropdown-toggle dropdown-toggle-arrow" [ngClass]="{'open': toggle.active}" msToggle #toggle="toggle" test-id="toggle-dropdown-btn">
    <span class="dropdown-toggle-value" test-id="toggle-dropdown-value">{{getItemText(selectedItem) | transloco}}</span>
    <span class=""><b></b></span>
  </a>
  <ul class="dropdown-menu open" test-id="toggle-dropdown-menu" *ngIf="toggle.active" [ngClass]="{'no-long-item': noLongItem}">
    <li class="dropdown-menu-item" *ngFor="let item of data">
      <a (click)="onSelect(item)" [attr.test-id]="'toggle-dropdown-value-' + getItemValue(item)">{{getItemText(item) | transloco}}</a>
    </li>
  </ul>
</div>
