<div class="locus-tooltip">
  <ng-container *ngIf="hasAlleles">
    <ng-container *ngFor="let allele of alleles; last as isLast">
      <ng-container *ngFor="let behaviorGroup of allele.behaviorGroups">
        <span>{{ allele.shortName }}</span><span *ngIf="allele.hlaExprChar">{{ allele.hlaExprChar }}</span>
        <span *ngIf="behaviorGroup.behaviorCategory">{{ ' ' + behaviorGroup.behaviorCategory }}</span>
        <span *ngIf="behaviorGroup.groupName">{{ ' ' + behaviorGroup.groupName }}</span>
      </ng-container><span *ngIf="!isLast">&nbsp;/&nbsp;</span><wbr>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hasGGroups">
    (<span *ngFor="let gGroup of gGroups; last as isLast">{{ gGroup }}<span *ngIf="!isLast">,&nbsp;</span></span>)
  </ng-container>

  <ng-container *ngIf="isNoResultsFound">{{ 'ALLELE.NO_RESULTS_FOUND' | transloco }}</ng-container>
</div>
