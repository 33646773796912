<div class="locus-tooltip">
  <ng-container *ngIf="isValidTyping else invalidTyping">
    <ng-container *ngIf="hasAlleles">
      <ng-container *ngFor="let allele of alleles; last as isLast">
        <span>{{ allele.shortName }}</span><span *ngIf="allele.hlaExprChar">{{ allele.hlaExprChar }}</span><span *ngIf="!isLast">&nbsp;/&nbsp;</span><wbr>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="hasGGroups">
      (<span *ngFor="let gGroup of gGroups; last as isLast">{{ gGroup }}<span *ngIf="!isLast">,&nbsp;</span></span>)
    </ng-container>
    <span *ngIf="!hasAlleles">{{ 'ALLELE.NO_RESULTS_FOUND' | transloco }}</span>
  </ng-container>
  <ng-template #invalidTyping>
    <span>{{ 'ALLELE.INVALID_TYPING' | transloco }}</span>
  </ng-template>
</div>
