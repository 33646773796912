import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {TransplantTimelineModel} from '@matchsource/models/nomenclature';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class TransplantTimelineApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getAll(): Observable<TransplantTimelineModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSubjectData);

    return this.nomenclaturesControllerService.loadTransplantTimelines(null, {context: context()});
  }
}
