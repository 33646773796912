import {Ng2StateDeclaration, UIView} from '@uirouter/angular';

import {cartRouteResolve} from '@matchsource/features/patient-root/shared';

const ROUTES: Array<Ng2StateDeclaration> = [
  {
    parent: 'patient.abstract-view',
    name: 'orders',
    component: UIView,
    url: '/orders',
    resolve: [cartRouteResolve],
    redirectTo: 'case-progress.donors',
  },
];

export {ROUTES};
