import {Action, State, StateContext} from '@ngxs/store';
import {CloseReasonsApiService} from '@matchsource/api/close-reasons';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {GetCloseReasons} from './close-reasons.action';
import {of} from 'rxjs';

import {Nomenclature} from '@matchsource/models/nomenclature';

export type CloseReasonsStateModel = EntitiesStateModel<Nomenclature>;

@State<CloseReasonsStateModel>({
  name: 'closeReasons',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class CloseReasonsState {
  constructor(private readonly closeReasonsApi: CloseReasonsApiService) {}

  @Action(GetCloseReasons)
  getAll(ctx: StateContext<CloseReasonsStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.closeReasonsApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
