import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap, map, finalize} from 'rxjs/operators';
import {GetCuratedSearchIds} from './curated-search.actions';
import {EbsProgramsApiService, SourceEnhancementProgram} from '@matchsource/api/ebs-programs';

export interface CuratedSearchStateModel {
  loading: boolean;
  loaded: boolean;
  ids: string[];
}

const initState = (): CuratedSearchStateModel => ({
  loading: false,
  loaded: false,
  ids: [],
});

@State<CuratedSearchStateModel>({
  name: 'curatedSearch',
  defaults: initState(),
})
@Injectable()
export class CuratedSearchState {
  constructor(private readonly ebsProgramsApiService: EbsProgramsApiService) {}

  @Selector([CuratedSearchState])
  static list(state: CuratedSearchStateModel): string[] {
    return state.ids;
  }

  @Action(GetCuratedSearchIds)
  getIds(ctx: StateContext<CuratedSearchStateModel>) {
    ctx.patchState({loading: true});

    return this.ebsProgramsApiService.getBpsEligibleForProgram(SourceEnhancementProgram.CuratedSearch).pipe(
      map(curatedSearch => curatedSearch.map(program => program.bpGuid)),
      tap(ids =>
        ctx.patchState({
          ids,
          loaded: true,
        })
      ),
      finalize(() => ctx.patchState({loading: false}))
    );
  }
}
