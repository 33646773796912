import {Pipe, PipeTransform} from '@angular/core';

import {UserRoles} from 'app/features/patient/services/patient-utils.service';
import {UserRolesService} from 'app/shared/services/user-roles.service';

@Pipe({
  name: 'role',
  standalone: true,
})
export class RolePipe implements PipeTransform {
  constructor(private readonly userRolesService: UserRolesService) {}

  transform(role: string): string {
    return this.userRolesService.resourceRoleDescriptions[role as UserRoles];
  }
}
