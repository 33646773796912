import {Action, State, StateContext} from '@ngxs/store';
import {MatchPreferencesApiService} from '@matchsource/api/match-preferences';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {GetMatchPreferences} from './match-preferences.action';
import {of} from 'rxjs';

import {Nomenclature} from '@matchsource/models/nomenclature';

export type MatchPreferencesStateModel = EntitiesStateModel<Nomenclature>;

@State<MatchPreferencesStateModel>({
  name: 'matchPreferences',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class MatchPreferencesState {
  constructor(private readonly matchPreferencesApi: MatchPreferencesApiService) {}

  @Action(GetMatchPreferences)
  getAll(ctx: StateContext<MatchPreferencesStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.matchPreferencesApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
