<div ms-toast-wrapper>
  <ng-template>
    <ul class="toast-message-body">
      <li>
        <span>
            {{'SEARCH_RESULTS.SEARCH_COMPLETED_SOURCE_TEXT' | transloco: {sourceType: data.sourceType} }}
            <b>
              <ms-patient-identification [patient]="data.patient" [phenotypeId]="data.search.phenotype"></ms-patient-identification>
            </b>
          {{'SEARCH_RESULTS.WAS_COMPLETED' | transloco}}
        </span>
      </li>
      <li class="button-wrapper" *ngIf="data.showSearchResultsLink">
        <button
          transloco="SEARCH_RESULTS.VIEW_SEARCH_RESULT"
          msButton="link"
          [disabled]="data.patient.coreSubmissionFailed"
          (click)="openSearchResult()"
        >
        </button>
      </li>
    </ul>
  </ng-template>
</div>
