<form
  class="field-group--overflow search-bar-wrapper"
  [formGroup]="searchForm"
  (ngSubmit)="onSubmit()"
>
  <div *ngIf="!hideTypesSelect" class="field-group-addon">
    <ul class="field-wrapper">
      <li class="field-element">
        <ms-toggle-dropdown
          formControlName="type"
          valueField="value"
          textField="text"
          [data]="searchTypeItems"
          [noLongItem]="noLongItem"
        >
        </ms-toggle-dropdown>
      </li>
    </ul>
  </div>
  <ul class="field-wrapper">
    <li class="field-element component-wrapper">
      <input
        type="text"
        formControlName="searchTerm"
        placeholder="{{ placeHolderValue | transloco }}"
        test-id="lookup-search-term"
      />
      <div *ngIf="isNeededToShowError()" test-id="error-container" class="field-element-error ng-active app-search-error-container">
        <ng-container [ngSwitch]="getError()">
          <div *ngSwitchCase="'patient_search_entry'" test-id="patient-search-entry-error" test-error-code="patient_search_entry">{{ 'APP_SEARCH.VALIDATION.SEARCH_PATIENT_INVALID_ENTRY' | transloco }}</div>
          <div *ngSwitchCase="'patient_search_id'" test-id="patient-search-id-error" test-error-code="patient_search_id">{{ 'APP_SEARCH.VALIDATION.SEARCH_PATIENT_INVALID_ID_LENGTH' | transloco : {limit: minIdLength} }}</div>
          <div *ngSwitchCase="'patient_search_name'" test-id="patient-search-name-error" test-error-code="patient_search_name">{{ 'APP_SEARCH.VALIDATION.SEARCH_PATIENT_INVALID_NAME_LENGTH' | transloco : {limit: minNameLength} }}</div>
          <div *ngSwitchCase="'patient_search_length'" test-id="patient-search-length-error" test-error-code="patient_search_length">{{ 'APP_SEARCH.VALIDATION.SEARCH_PATIENT_INVALID_ADULT_LENGTH' | transloco : {limit: adultSearchLength} }}</div>
        </ng-container>
      </div>
    </li>
  </ul>
  <div class="field-group-button">
    <button type="submit" class="search-button" test-id="lookup-search-button">
      <i aria-hidden="true" class="loup"></i>
    </button>
  </div>
</form>
