import {Injectable, OnDestroy} from '@angular/core';
import {TooltipService, ExtendedCreateOptions} from '@matchsource/nmdp-ui';
import {DelegateInstance} from 'tippy.js';
import {AlleleApiService} from '@matchsource/api/allele';
import {ExtendedTippyInstance} from '@ngneat/helipopper';
import {LocusTooltipComponent} from 'app/shared/components/locus-tooltip/locus-tooltip.component';

import {Dpb1TooltipComponent} from 'app/shared/components/dpb1-tooltip/dpb1-tooltip.component';
import {firstValueFrom} from 'rxjs';

@Injectable()
export class ApplicationTooltipService implements OnDestroy {
  private readonly tooltips: DelegateInstance[] = [];

  constructor(
    private readonly tooltipService: TooltipService,
    private readonly alleleApiService: AlleleApiService
  ) {}

  init() {
    const alleleTooltipBaseConfig: Partial<ExtendedCreateOptions<unknown, unknown>> = {
      placement: 'top',
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 2,
              rootBoundary: 'viewport',
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['right', 'left', 'top', 'bottom'],
              padding: 2,
              rootBoundary: 'viewport',
            },
          },
        ],
      },
      resolve: (instance: ExtendedTippyInstance<unknown>) => {
        const refEl = instance.reference;
        const locusName = refEl.getAttribute('data-typing').replace('HLA-', '');
        const groName = refEl.getAttribute('data-typing-value');

        return firstValueFrom(this.alleleApiService.getShortAllele(locusName, groName, {backgroundRequest: true}));
      },
    };

    const locusTooltip = this.tooltipService.delegate('.typing:not(.DPB1)', LocusTooltipComponent, {
      ...alleleTooltipBaseConfig,
    });
    const dpb1Tooltip = this.tooltipService.delegate('.typing.DPB1', Dpb1TooltipComponent, {
      ...alleleTooltipBaseConfig,
    });

    this.tooltips.push(locusTooltip, dpb1Tooltip);
  }

  ngOnDestroy(): void {
    this.tooltips.forEach(tooltip => tooltip.destroy());
  }
}
