import {Transition} from '@uirouter/angular';
import {LoginComponent} from 'app/core/components/login/login.component';
import {AuthService} from 'app/core/services/auth.service';
import {FeatureToggleStateDeclaration} from '@matchsource/feature-toggle';
import {SampleInstructionRoutes} from 'app/features/sample-instruction/declarations';
import {getLazyLoadedRoute} from '@matchsource/utils';
import {AppComponent} from 'app/core/components/app/app.component';
import {RouterStateService} from 'ngxs-ui-router';
import {NavigationHistoryService} from '@matchsource/navigation-history';
import {firstValueFrom} from 'rxjs';
import {AppRoutes} from './declarations';
import {PatientFormRoutes} from 'app/features/patient-form/declarations';
import {MyPatientsRoutes} from 'app/features/my-patients/declarations';
import {AdvancedPatientLookupRoutes} from 'app/features/advanced-patient-lookup/declarations';
import {SearchStatusRoutes} from 'app/features/search-status/declarations';
import {PatientsInProgressRoutes} from 'app/features/patients-in-progress/declarations';
import {WorkflowManagerRoutes} from 'app/features/workflow-manager/declarations';
import {UserService} from '@matchsource/core';
import {USER_PERMISSIONS} from '@matchsource/models/permissions';
import {PermissionsService} from '@matchsource/store/permissions';
import {DonorWorkupFormRoutes} from '@matchsource/donor-workup-form/shared';
import {UserRolesService} from 'app/shared/services/user-roles.service';

export const LOGIN_STATE_NAME = 'login';
export const LOGOUT_STATE_NAME = 'logout';

export async function logoutRedirectTo(transition: Transition) {
  const params = transition.params('to');
  const {$skipGuards = false, logoutManually = false, refreshLogin = false} = params;
  const auth = transition.injector().get<AuthService>(AuthService);
  await firstValueFrom(auth.logout(logoutManually));
  return {state: LOGIN_STATE_NAME, params: {$skipGuards, refreshLogin}};
}

export async function resolveSessionStatus(
  authService: AuthService,
  router: RouterStateService
): Promise<void | never> {
  const isAlive = await authService.isSessionAlive();
  if (!isAlive) {
    await router.navigate('logout', {$skipGuards: true});
    return Promise.reject();
  }

  return Promise.resolve();
}

export function resolveNavigationHistory(navigationHistoryService: NavigationHistoryService): Promise<void> {
  return navigationHistoryService.loadHistory();
}

export function resolveResourceRoles(userRolesService: UserRolesService): Promise<void> {
  return userRolesService.fetchResourceRoles();
}

export async function coreGuard(transition: Transition) {
  const $state = transition.router.stateService;

  const injector = transition.injector();
  const permissions = injector.get<PermissionsService>(PermissionsService);
  const user = injector.get<UserService>(UserService);

  if (user.isLoggedIn()) {
    await permissions.load();
  }

  if (!permissions.hasPermissions(USER_PERMISSIONS.ACCESS_TO_PROTECTED_AREA)) {
    return $state.target('empty');
  }

  if (!user.isTcAccepted) {
    return $state.target('terms');
  }
}

const ROUTES: Array<FeatureToggleStateDeclaration> = [
  {
    name: AppRoutes.Core,
    url: '/core',
    component: AppComponent,
    onEnter: coreGuard,
    redirectTo: 'dashboard',
    resolve: [
      {
        token: 'sessionStatus',
        deps: [AuthService, RouterStateService],
        resolveFn: resolveSessionStatus,
      },
      {
        token: 'navigationHistory',
        deps: [NavigationHistoryService, 'sessionStatus'],
        resolveFn: resolveNavigationHistory,
      },
      {
        token: 'resourceRoles',
        deps: [UserRolesService],
        resolveFn: resolveResourceRoles,
      },
    ],
  },
  {
    name: 'empty',
    url: '/empty',
    component: AppComponent,
    data: {
      requireAuthorization: true,
    },
  },
  {
    name: 'terms.**',
    url: '/terms',
    loadChildren: () => import('app/features/terms/terms.module').then(m => m.TermsModule),
  },
  {
    name: LOGIN_STATE_NAME,
    url: '/login',
    component: LoginComponent,
    data: {
      requireAuthorization: false,
      disallowAuthorizedUsers: true,
      cleanupMemory: true,
    },
    params: {
      returnState: null,
      refreshLogin: false,
      params: null,
      $skipGuards: false,
    },
  },
  {
    name: 'logout',
    url: '/logout',
    params: {
      $skipGuards: false,
      logoutManually: false,
      refreshLogin: false,
    },
    redirectTo: logoutRedirectTo,
  },
  {
    parent: 'core',
    name: 'switch-user-role.**',
    url: '/switch-user-role',
    loadChildren: () =>
      import('app/features/switch-user-role/switch-user-role.module').then(m => m.SwitchUserRoleModule),
  },
  {
    parent: 'core',
    name: 'donor.**',
    url: '/donor',
    loadChildren: () => import('app/features/donor/donor.module').then(m => m.DonorModule),
  },
  {
    parent: 'core',
    name: 'cord.**',
    url: '/cord',
    loadChildren: () => import('app/features/cord/cord.module').then(m => m.CordModule),
  },
  {
    parent: 'core',
    name: 'biobank.**',
    url: '/biobank',
    loadChildren: () => import('app/features/biobank/biobank.module').then(m => m.BiobankModule),
  },
  {
    parent: 'core',
    name: 'secondary-codes.**',
    url: '/secondary-codes',
    loadChildren: () => import('app/features/secondary-codes/secondary-codes.module').then(m => m.SecondaryCodesModule),
  },
  {
    parent: 'core',
    name: 'potential-duplicates.**',
    url: '/potential-duplicates',
    loadChildren: () =>
      import('app/features/potential-duplicates/potential-duplicates.module').then(m => m.PotentialDuplicatesModule),
  },
  {
    parent: 'orders',
    name: 'case-progress.**',
    url: '/case-progress',
    loadChildren: () => import('app/features/case-progress/case-progress.module').then(m => m.CaseProgressModule),
  },
  {
    parent: 'core',
    name: 'settings.**',
    url: '/settings',
    loadChildren: () => import('app/features/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    parent: 'core',
    name: 'admin-tools.**',
    url: '/admin-tools',
    loadChildren: () => import('app/features/admin-tools/admin-tools.module').then(m => m.AdminToolsModule),
  },
  {
    parent: 'core',
    name: SampleInstructionRoutes.Any,
    url: '/sample-instruction',
    loadChildren: () =>
      import('app/features/sample-instruction/sample-instruction.module').then(m => m.SampleInstructionModule),
  },
  {
    parent: 'core',
    name: 'donors-lookup.**',
    url: '/donors-lookup',
    loadChildren: () => import('@matchsource/features/donors-lookup').then(m => m.DonorsLookupModule),
  },
  {
    parent: 'core',
    name: 'cords-lookup.**',
    url: '/cords-lookup',
    loadChildren: () => import('@matchsource/features/cords-lookup').then(m => m.CordsLookupModule),
  },
  {
    parent: 'core',
    name: 'patients-lookup.**',
    url: '/patients-lookup',
    loadChildren: () => import('@matchsource/features/patients-lookup').then(m => m.PatientsLookupModule),
  },
  {
    parent: 'core',
    name: getLazyLoadedRoute(SearchStatusRoutes.Search),
    url: '/search',
    loadChildren: () => import('app/features/search-status/search-status.module').then(m => m.SearchStatusModule),
  },
  {
    parent: 'core',
    name: 'forms-due.**',
    url: '/forms-due',
    loadChildren: () => import('app/features/forms-due/forms-due.module').then(m => m.FormsDueModule),
  },
  {
    parent: 'core',
    name: 'dashboard.**',
    url: '/dashboard',
    loadChildren: () => import('app/features/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    parent: 'core',
    name: getLazyLoadedRoute(AdvancedPatientLookupRoutes.AdvancedLookup),
    url: '/advanced-lookup',
    loadChildren: () =>
      import('app/features/advanced-patient-lookup/advanced-patient-lookup.module').then(
        m => m.AdvancedPatientLookupModule
      ),
  },
  {
    name: 'patient-search.opl.opl-ssa.**',
    url: '/ssa',
    loadChildren: () => import('app/features/opl/opl.module').then(m => m.OplModule),
  },
  {
    name: 'patient-search.opl.opl-standard.**',
    url: '/standard',
    loadChildren: () => import('app/features/opl-standard/opl-standard.module').then(m => m.OplStandardModule),
  },
  {
    parent: 'patient.abstract-view',
    name: 'order-details.**',
    url: '/orders',
    loadChildren: () => import('app/features/order-details/order-details.module').then(m => m.OrderDetailsModule),
  },
  {
    name: 'patient.abstract-view.view.**',
    url: '/view',
    loadChildren: () => import('app/features/patient/patient.module').then(m => m.PatientModule),
  },
  {
    parent: 'patient.abstract-view',
    name: 'workup-form.**',
    url: '/workup-form',
    loadChildren: () => import('app/features/workup-form/workup-form.module').then(m => m.WorkupFormModule),
  },
  {
    parent: 'patient.abstract-view',
    name: 'coop.**',
    url: '/coop',
    loadChildren: () => import('app/features/coop/coop.module').then(m => m.CoopModule),
  },
  {
    parent: 'core',
    name: getLazyLoadedRoute(WorkflowManagerRoutes.Workflow),
    url: '/workflow',
    loadChildren: () =>
      import('app/features/workflow-manager/workflow-manager.module').then(m => m.WorkflowManagerModule),
  },
  {
    parent: 'core',
    name: getLazyLoadedRoute(MyPatientsRoutes.MyPatients),
    url: '/my-patients',
    loadChildren: () => import('app/features/my-patients/my-patients.module').then(m => m.MyPatientsModule),
  },
  {
    parent: 'core',
    name: 'patient-swab-kit-orders.**',
    url: '/patient-swab-kit-orders',
    loadChildren: () => import('app/features/swab-kit-orders/swab-kit-orders.module').then(m => m.SwabKitOrdersModule),
  },
  {
    parent: 'core',
    name: 'bulk-swab-kit-orders.**',
    url: '/bulk-swab-kit-orders',
    loadChildren: () => import('app/features/swab-kit-orders/swab-kit-orders.module').then(m => m.SwabKitOrdersModule),
  },
  {
    parent: 'core',
    name: 'swab-kit-order.**',
    url: '/swab-kit-order',
    loadChildren: () => import('app/features/swab-kit-order/swab-kit-order.module').then(m => m.SwabKitOrderModule),
  },
  {
    parent: 'core',
    name: getLazyLoadedRoute(PatientsInProgressRoutes.PatientsInProgress),
    url: '/patients-in-progress',
    loadChildren: () =>
      import('app/features/patients-in-progress/patients-in-progress.module').then(m => m.PatientsInProgressModule),
  },
  {
    parent: 'patient.abstract-view',
    name: 'patient-search.**',
    url: '/search',
    loadChildren: () => import('app/features/search-results/search-results.module').then(m => m.SearchResultsModule),
  },
  {
    parent: 'core',
    name: 'analytics.**',
    url: '/analytics',
    loadChildren: () => import('app/features/analytics/analytics.module').then(m => m.AnalyticsModule),
  },
  {
    parent: 'patient.abstract-view',
    name: 'patient-cart.**',
    url: '/cart',
    loadChildren: () => import('app/features/cart/cart.module').then(m => m.CartModule),
  },
  {
    parent: 'core',
    name: 'biobanks-lookup.**',
    url: '/biobanks-lookup',
    loadChildren: () => import('@matchsource/features/biobanks-lookup').then(m => m.BiobanksLookupModule),
  },
  {
    parent: 'patient.abstract-view',
    name: getLazyLoadedRoute(DonorWorkupFormRoutes.DonorWorkupForm),
    url: '/donor-workup-form',
    loadChildren: () =>
      import('app/features/donor-workup-form/donor-workup-form.module').then(m => m.DonorWorkupFormModule),
  },
  {
    parent: 'patient.abstract-view',
    name: getLazyLoadedRoute('subsequent-donor-workup'),
    url: '/subsequent-donor-workup',
    loadChildren: () =>
      import('@matchsource/features/subsequent-donor-workup').then(m => m.SubsequentDonorWorkupModule),
  },
  {
    url: '/form',
    name: getLazyLoadedRoute(PatientFormRoutes.PatientForm),
    loadChildren: () => import('app/features/patient-form/patient-form.module').then(m => m.PatientFormModule),
  },
  {
    name: 'patient.abstract-view.ssa-request.**',
    url: '/ssa-request',
    loadChildren: () =>
      import('app/features/patient-ssa-request/patient-ssa-request.module').then(m => m.PatientSsaRequestModule),
  },
];

export {ROUTES};
