import {Injectable, OnDestroy} from '@angular/core';
import {Actions, InitState, ofActionSuccessful, Store, UpdateState} from '@ngxs/store';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable()
export class ResetService implements OnDestroy {
  private initState: any;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {
    this.actions$.pipe(ofActionSuccessful(InitState), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.initState = {...this.store.snapshot()};
    });

    this.actions$.pipe(ofActionSuccessful(UpdateState), takeUntil(this.destroy$)).subscribe(({addedStates}) => {
      this.initState = {
        ...this.initState,
        ...addedStates,
      };
    });
  }

  reset(): void {
    this.store.reset({...this.initState});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
