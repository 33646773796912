import {NgModule} from '@angular/core';
import {FeatureToggleModule, FeatureToggleAdapterService} from '@matchsource/feature-toggle';
import {FeatureToggleApiService} from '@matchsource/api/feature-toggle';

@NgModule({
  exports: [FeatureToggleModule],
  imports: [FeatureToggleModule.forRoot()],
  providers: [
    {
      provide: FeatureToggleAdapterService,
      useClass: FeatureToggleApiService,
    },
  ],
})
export class FeatureToggleRootModule {}
