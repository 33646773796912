import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ApiConfigService, ApiConfig} from './config';
import {MsAppApiConfig} from './declarations';

const rawConfigService = new InjectionToken<MsAppApiConfig>('ApiModuleConfig');

export function createConfigFactory(config: MsAppApiConfig): ApiConfig {
  return {
    endpoint: config.api_endpoint,
    commonServiceUrl: `${config.api_endpoint}${config.context.common}/`,
    searchMatchServiceUrl: `${config.api_endpoint}${config.context.searchMatch}/`,
    orderServiceUrl: `${config.api_endpoint}${config.context.order}/`,
    subjectServiceUrl: `${config.api_endpoint}${config.context.subject}/`,
    documentsServiceUrl: `${config.api_endpoint}${config.context.documents}/`,
    notificationsServiceUrl: `${config.api_endpoint}${config.context.notifications}/`,
    configsServiceUrl: `${config.api_endpoint}${config.context.configs}/`,
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
})
export class ApiModule {
  static forRoot(config: any): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        // we need it for AOT support
        {
          provide: rawConfigService,
          useValue: config,
        },
        {
          provide: ApiConfigService,
          useFactory: createConfigFactory,
          deps: [rawConfigService],
        },
      ],
    };
  }
}
