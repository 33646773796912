export enum CpLayoutHeaderTestIds {
  MsLogoDashboard = 'ms-logo-to-dashboard',
  WordmarkLogo = 'ms-logo-to-dashboard-wordmark',
  MyPatientButton = 'my-patients-button',
  BulkSwabKits = 'bulk-swab-kits-button',
  NotificationLink = 'notification-link',
  StatusPage = 'status-page',
  UserMenu = 'user-menu',
  TimingGuidelines = 'timing-guidelines',
  ContactGuides = 'contact-guides',
  ReferenceGuide = 'reference-guide',
  UserLogout = 'user-logout',
  NavToolbarLogout = 'nav-toolbar-logout',
  AdminTools = 'admin-tools',
  SampleInstruction = 'sample-instruction',
}
