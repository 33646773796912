export enum NavigationHeaderTestIds {
  MsLogoDashboard = 'ms-logo-to-dashboard',
  WordmarkLogo = 'ms-logo-to-dashboard-wordmark',
  ManagerLink = 'workflow-manager-link',
  PatientsLink = 'patients-links',
  CreateNewPatient = 'create-new-patient',
  PatientInProgress = 'patient-in-progress',
  MyPatients = 'my-patients',
  HeaderSearchStatus = 'header-search-status',
  StatusPage = 'status-page',
  AdvancedLookup = 'advanced-lookup',
  UserMenu = 'user-menu',
  MyPatientsLink = 'my-patients-link',
  SwabKitOrders = 'swab-kit-orders',
  WorkFlowManager = 'workflow-manager',
  AdminTools = 'admin-tools',
  FormsDue = 'forms-due',
  SecondaryCodes = 'secondary-codes',
  PotentialDuplicates = 'potential-duplicates',
  SampleInstruction = 'sample-instruction',
  Settings = 'settings',
  Logout = 'logout',
  CpLayout = 'ms-cp-layout',
  MsAnnouncement = 'ms-announcement',
  UiSpinner = 'ui-spinner',
}
