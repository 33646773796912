import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {shortAlleleModelFromDto} from './short-allele.serializer';
import {map} from 'rxjs/operators';
import {ShortAlleleModel} from '@matchsource/models/allele';
import flow from 'lodash-es/flow';

import {useSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class AlleleApiService {
  constructor(private readonly nomenclaturesController: NomenclaturesControllerService) {}

  getShortAllele(
    longLocusName: string,
    groName: string,
    {backgroundRequest = false}: {backgroundRequest?: boolean} = {}
  ): Observable<ShortAlleleModel | null> {
    if (!longLocusName || !groName) {
      return of(null);
    }

    const context = flow(
      useSpinner(backgroundRequest),
      setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingReferenceData)
    );

    // Remove 's' for Serology values
    if (groName.slice(0, 1) === 's') {
      groName = groName.substr(1, groName.length);
    }

    return this.nomenclaturesController
      .getShortAlleles(
        {
          longLocusName,
          groName,
        },
        {
          context: context(),
        }
      )
      .pipe(map(dto => shortAlleleModelFromDto(dto)));
  }
}
