import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TIPPY_REF, TippyInstance} from '@matchsource/nmdp-ui';
import {ShortAlleleAlleleModel, ShortAlleleGGroupModel, ShortAlleleModel} from '@matchsource/models/allele';
import {TranslocoPipe} from '@jsverse/transloco';
import {NgIf, NgFor} from '@angular/common';

@Component({
  selector: 'ms-dpb1-tooltip',
  templateUrl: './dpb1-tooltip.component.html',
  styleUrls: ['./dpb1-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, TranslocoPipe],
})
export class Dpb1TooltipComponent {
  get shortAllele(): ShortAlleleModel | null {
    return this.tippy.data;
  }

  get alleles(): ShortAlleleAlleleModel[] {
    return this.shortAllele?.alleles;
  }

  get hasAlleles(): boolean {
    return this.alleles?.length > 0;
  }

  get gGroups(): ShortAlleleGGroupModel[] {
    return this.shortAllele?.gGroups;
  }

  get hasGGroups(): boolean {
    return this.gGroups?.length > 0;
  }

  get hasResults(): boolean {
    return this.hasAlleles && this.alleles.some(allele => allele.behaviorGroups?.length > 0);
  }

  get isNoResultsFound(): boolean {
    return this.alleles?.length === 0 && !this.hasResults;
  }

  constructor(@Inject(TIPPY_REF) public tippy: TippyInstance) {}
}
