import {Injectable} from '@angular/core';
import {Action, State} from '@ngxs/store';
import {DialogService} from '@matchsource/nmdp-ui';
import {RouterSuccess} from 'ngxs-ui-router';
import {ERROR_DIALOG_ID} from '@matchsource/error-handling/core';

@State<void>({
  name: 'dialogs',
})
@Injectable()
export class DialogsState {
  constructor(private readonly dialog: DialogService) {}

  @Action([RouterSuccess])
  closeAll() {
    this.dialog.closeAllExcludingIds([ERROR_DIALOG_ID]);
  }
}
