import {notEmptyRegexpValidator} from '../utils';

export const FULLNAME_VALIDATOR_KEY = 'fullname';

export const fullNameValidator = notEmptyRegexpValidator(
  [
    /^(\S)+.*$/i, // no leading spaces
    /^.*(\S)+$/i, // no trailing spaces
    /^[A-Za-zÀ-ÖØ-öø-ÿ\-. ']+, [A-Za-zÀ-ÖØ-öø-ÿ\-. ']+$/i,
  ],
  FULLNAME_VALIDATOR_KEY
);
