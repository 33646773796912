import {enableProdMode, NgZone} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app/app.module';
import {environment} from './environments/environment';
import {UIRouter, UrlService} from '@uirouter/core';

if (environment.production) {
  enableProdMode();
}

// Don't add {ngZoneEventCoalescing: true}. Otherwise, that will lead to https://jira.nmdp.org/browse/MS-25844
// Can be added after removal of 'fake-page' (AngularJs) and thorough testing
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(platformRef => {
    // This code is related to deferIntercept() call in routing.config.ts
    // Intialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
    const urlService: UrlService = platformRef.injector.get<UIRouter>(UIRouter).urlService;

    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
      urlService.listen();
      urlService.sync();
    }

    platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
  })
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
