import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {TranslocoPipe} from '@jsverse/transloco';
import {DynamicDialogComponent} from '@matchsource/nmdp-ui';
import {ConfirmationDialogComponent} from '@matchsource/shared/dialog';

@Component({
  selector: 'ms-feature-changed-modal',
  templateUrl: './feature-changed-modal.component.html',
  styleUrl: './feature-changed-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DynamicDialogComponent, ConfirmationDialogComponent, TranslocoPipe],
})
export class FeatureChangedModalComponent implements AfterViewInit {
  @ViewChild(DynamicDialogComponent)
  dynamicDialogComponent: DynamicDialogComponent;

  ngAfterViewInit(): void {
    this.dynamicDialogComponent.removeCloseOption();
  }
}
