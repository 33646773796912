import {Nomenclature} from '@matchsource/models/nomenclature';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {DiseaseStagesApiService} from '@matchsource/api/disease-stages';
import {GetDiseaseStages} from './disease-stages.action';
import {patch} from '@ngxs/store/operators';
import {tap} from 'rxjs/operators';

export interface DiseaseStagesStateModel {
  loading: boolean;

  loaded: boolean;

  stages: Nomenclature[];

  error: any;
}

export interface StagesStateModel {
  [code: string]: DiseaseStagesStateModel;
}

const defaultDiseaseStagesState = (overrides = {}): DiseaseStagesStateModel => ({
  loading: false,
  loaded: false,
  stages: [],
  error: undefined,
  ...overrides,
});

@State<StagesStateModel>({
  name: 'diseaseStages',
  defaults: {},
})
@Injectable()
export class DiseaseStagesState {
  @Selector([DiseaseStagesState])
  static diseaseStages(state: DiseaseStagesStateModel) {
    return (code: string) => {
      return code in state ? state[code].stages : [];
    };
  }

  constructor(private readonly diseaseStagesApi: DiseaseStagesApiService) {
  }

  @Action(GetDiseaseStages)
  getDiseaseStages(ctx: StateContext<StagesStateModel>, {diseaseCode}: GetDiseaseStages) {
    const state = ctx.getState();
    const tcState = diseaseCode in state ? state[diseaseCode] : null;

    if (tcState && (tcState.loaded || tcState.loading)) {
      return;
    }

    ctx.setState(
      patch({
        [diseaseCode]: defaultDiseaseStagesState({loading: true}),
      })
    );

    return this.diseaseStagesApi.get(diseaseCode).pipe(
      tap(stages => {
        ctx.setState(
          patch({
            [diseaseCode]: patch<DiseaseStagesStateModel>({
              loading: false,
              loaded: true,
              stages,
            }),
          })
        );
      })
    );
  }
}
