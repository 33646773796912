import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslocoPipe} from '@jsverse/transloco';
import {NgClass, NgIf, NgFor} from '@angular/common';
import {ToggleDirective} from '@matchsource/shared/toggle';
import {SearchTypes} from '@matchsource/models/search';

@Component({
  selector: 'ms-toggle-dropdown',
  templateUrl: './toggle-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./toggle-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleDropdownComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ToggleDirective, NgClass, NgIf, NgFor, TranslocoPipe],
})
export class ToggleDropdownComponent implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _value: any;

  @Input()
  data: any[];

  @Input()
  textField: string;

  @Input()
  valueField: string;

  biobank: boolean;

  @Input()
  noLongItem: boolean;

  @Input()
  set value(val: any) {
    this._value = val;

    this.selectedItem = this.findItem(val, this.data);
  }

  get value() {
    return this._value;
  }

  selectedItem: any;

  private onValueChange: any;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any): void {}

  writeValue(obj: any): void {
    this.value = obj;

    this.cdRef.markForCheck();
  }

  onSelect(item: any) {
    const value = this.getItemValue(item);
    this.biobank = value === SearchTypes.Biobank;

    if (this.onValueChange) {
      this.onValueChange(value);
    }

    this.value = value;
  }

  getItemValue(item: any) {
    return item ? item[this.valueField] : undefined;
  }

  getItemText(item: any) {
    return item ? item[this.textField] : null;
  }

  findItem(value: any, items: any[]) {
    return items.find(item => this.getItemValue(item) === value);
  }
}
