<ms-dynamic-dialog customClass="feature-changed-modal">
  <ng-template let-data let-modal="modal">
    <ms-confirmation-dialog
      [identifier]="modal.identifier"
      [caption]="'FEATURE_CHANGED.HEADER' | transloco"
      [confirmText]="'FEATURE_CHANGED.RESTART' | transloco"
      [hideCancel]="true"
    >
      <span [innerHTML]="'FEATURE_CHANGED.MESSAGE' | transloco"></span>
    </ms-confirmation-dialog>
  </ng-template>
</ms-dynamic-dialog>
