import {Inject, Injectable} from '@angular/core';
import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable, catchError, firstValueFrom, from, throwError} from 'rxjs';

import {ERROR_CODE_ZERO, ErrorCodeZeroContextData} from '@matchsource/core';
import {ApiConfig, ApiConfigService} from '@matchsource/api-config';

@Injectable({
  providedIn: 'root',
})
export class ErrorCodeZeroResponseInterceptor implements HttpInterceptor {
  private readonly bypassInterceptorsHttpClient: HttpClient;
  private readonly healthcheckUrl: string;

  constructor(
    private readonly httpBackend: HttpBackend,
    @Inject(ApiConfigService) private readonly apiConfig: ApiConfig
  ) {
    // When using HttpBackend any HttpClient response will not flow through the interceptors
    // We need this behavior for the additional diagnostics requests we will make in case of response error status 0,
    // which should not be processed by any interceptor
    this.bypassInterceptorsHttpClient = new HttpClient(this.httpBackend);
    this.healthcheckUrl = `${this.apiConfig.configsServiceUrl}actuator/health`;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const errRes = err as HttpErrorResponse;
        if (errRes.status === 0) {
          return from(this.handleErrorCodeZero(request, err));
        } else {
          return throwError(() => err);
        }
      })
    );
  }

  async handleErrorCodeZero(request: HttpRequest<any>, error: any): Promise<HttpEvent<any>> {
    const contextData: ErrorCodeZeroContextData = {
      retryAttempted: false,
    };

    // First check connection with the server and store the response status in the request context
    try {
      const authorizationHeaderValue = request.headers.get('authorization');
      const healthcheckRes = await firstValueFrom(this.getHealtcheckResponse(authorizationHeaderValue));
      contextData.networkCheckHttpResponse = healthcheckRes.status;
    } catch (err) {
      if (Number.isInteger(err.status)) {
        contextData.networkCheckHttpResponse = err.status;
      }
    }

    // If the failed request was GET, retry it and store the response status in the request context
    if (request.method === 'GET') {
      try {
        contextData.retryAttempted = true;
        const retryRes = await firstValueFrom(
          this.bypassInterceptorsHttpClient.get(request.url, {
            headers: request.headers,
            context: request.context,
            observe: 'response',
            params: request.params,
            withCredentials: request.withCredentials,
          })
        );
        contextData.retryHttpResponse = retryRes.status;
      } catch (err) {
        if (Number.isInteger(err.status)) {
          contextData.retryHttpResponse = err.status;
        }
      }
    }

    request.context.set(ERROR_CODE_ZERO, contextData);

    return firstValueFrom(throwError(() => error));
  }

  getHealtcheckResponse(authorizationHeaderValue: string): Observable<HttpResponse<void>> {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('Authorization', authorizationHeaderValue);
    return this.bypassInterceptorsHttpClient.get<void>(this.healthcheckUrl, {
      headers,
      observe: 'response',
    });
  }
}
