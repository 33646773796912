import {Action, State, StateContext} from '@ngxs/store';
import {ClinicalRationaleApiService} from '@matchsource/api/clinical-rationale';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';
import {Nomenclature} from '@matchsource/models/nomenclature';
import {GetClinicalRationale} from './clinical-rationale.actions';

export type ClinicalRationaleStateModel = EntitiesStateModel<Nomenclature>;

@State<ClinicalRationaleStateModel>({
  name: 'clinicalRationale',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class ClinicalRationaleState {
  constructor(private readonly closeReasonsApi: ClinicalRationaleApiService) {}

  @Action(GetClinicalRationale)
  getAll(ctx: StateContext<ClinicalRationaleStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.closeReasonsApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
