<ul class="cp-container">
  <li class="ms-logo-area">
    <a [attr.test-id]="testIds.MsLogoDashboard" [uiSref]="dashboardLink">
      <ms-logo-component></ms-logo-component>
    </a>
  </li>

  <li class="my-patients-area menu-area">
    <div class="container">
      <ms-options
        [label]="'MY_PATIENTS.MY_PATIENTS' | transloco"
        labelClasses="menu-link white-highlight"
        [areOptionsSelectable]="true"
        orientation="left"
        [items]="myPatientsOptions"
        [item]="currentRoute$ | async"
        (selectOption)="onLinkChanged($event)"
        [attr.test-id]="testIds.MyPatientButton"
      >
      </ms-options>
    </div>
  </li>

  <li class="bulk-swab-kits-area menu-area">
    <div class="container">
      <ms-options
        [label]="'SWAB_KIT_ORDERS.BULK_SWAB_KITS' | transloco"
        labelClasses="menu-link white-highlight"
        [areOptionsSelectable]="true"
        orientation="left"
        [items]="bulkSwabKitsOptions"
        [item]="currentRoute$ | async"
        (selectOption)="onLinkChanged($event)"
        [attr.test-id]="testIds.BulkSwabKits"
      >
      </ms-options>
    </div>
  </li>

  <li class="notifications-area menu-area">
    <div class="container">
        <a class="menu-link" [attr.test-id]="testIds.NotificationLink" [uiSref]="notificationsLink" transloco="COMMON.NOTIFICATIONS" [ngClass]="{'selected': isNotificationsLinkActive(currentRoute$ | async)}"></a>
    </div>
  </li>

  <li class="search-area">
    <ul class="container">
      <li>
        <ms-search
          [attr.test-id]="testIds.StatusPage"
          [searchTypes]="searchTypes"
          [searchParams]="searchOptions"
          (search)="onSearch($event)"
          [hideTypesSelect]="true"
          placeholder="COMMON.LOOKUP_PATIENT_PLACEHOLDER"
        >
        </ms-search>
      </li>
    </ul>
  </li>

  <li class="user-area menu-area">
    <div class="container">
      <div class="ms-dropdown">
        <a class="user-menu-container"
           [attr.test-id]="testIds.UserMenu"
           [ngClass]="{'open': protectedUserMenuToggle.active, 'multiple-role-user': isMultipleRolesUser}" msToggle #protectedUserMenuToggle="toggle">{{ userNameAbbr | uppercase }}</a>
        <ul class="dropdown-menu wrapper" [ngClass]="{'open': protectedUserMenuToggle.active}" *ngIf="hasAccessToProtectedArea">
          <li class="role"><b>{{userFullName | uppercase}}</b></li>
          <li class="dropdown-menu-note user-name">
            <span>{{userName}}</span>
          </li>

          <ms-switch-roles *ngIf="isMultipleRolesUser" [userRoles]="rolesForSwitching" [activeRole]="userRole" (switchRole)="onSwitchRole($event)">
          </ms-switch-roles>

          @if (isApplicationToolsVisible) {
            <li class="dropdown-menu-divider"></li>
            <li><b transloco="NAV_TOOLBAR.APPLICATION_TOOLS"></b></li>
            <li class="dropdown-menu-item" *msUserPermissions="announcementsEditPermissions">
              <a [attr.test-id]="testIds.AdminTools" uiSref="admin-tools" transloco="ADMIN_TOOLS.ADMIN_TOOLS"></a>
            </li>
            <li class="dropdown-menu-item" *msUserPermissions="sampleInstructionPermissions">
              <a [attr.test-id]="testIds.SampleInstruction" uiSref="sample-instruction" transloco="SIT.HEADER"></a>
            </li>
          }

          <li class="dropdown-menu-divider"></li>
          <li><b transloco="NAV_TOOLBAR.TRAINING_AND_RESOURCES"></b></li>
          <li class="dropdown-menu-item external-item-wrapper">
            <a [attr.test-id]="testIds.TimingGuidelines" class="external-link" href="https://bethematchclinical.org/transplant-indications-and-outcomes/referral-timing-guidelines/"
               target="_blank" rel="noopener">
              <span transloco="NAV_TOOLBAR.TRANSPLANT_CONSULTATION_TIMING_GUIDELINES"></span>
              <span class="external-link-ico"></span>
            </a>

          </li>
          <li class="dropdown-menu-item external-item-wrapper">
            <a [attr.test-id]="testIds.ContactGuides" class="external-link" href="https://www2.bethematchclinical.org/access-resource-hub"
               target="_blank" rel="noopener">
              <span transloco="NAV_TOOLBAR.TRANSPLANT_CENTER_CONTACT_GUIDES"></span>
              <span class="external-link-ico"></span>
            </a>

          </li>
          <li class="dropdown-menu-item external-item-wrapper">
            <a [attr.test-id]="testIds.ReferenceGuide" class="external-link" href="https://app.cloud.scorm.com/sc/InvitationConfirmEmail?publicInvitationId=cebf44e7-3082-4c29-a5ac-4638cbdecda8"
               target="_blank" rel="noopener">
              <span transloco="NAV_TOOLBAR.MATCHSOURCE_QUICK_REFERENCE_GUIDE"></span>
              <span class="external-link-ico"></span>
            </a>
          </li>
          <li class="dropdown-menu-divider"></li>
          <li class="dropdown-menu-item">
            <a [attr.test-id]="testIds.UserLogout" (click)="onLogout()" transloco="USER.LOGOUT"></a>
          </li>
          <li class="dropdown-menu-note">
            <span>{{ 'NAV_TOOLBAR.LAST_LOGIN' | transloco }}: <span class="uppercase-text">{{ lastLogin | date : lastLoginDateFormat }}</span></span>
          </li>
        </ul>
        <ul id="userMenu" class="dropdown-menu" *ngIf="!hasAccessToProtectedArea">
          <li class="dropdown-menu-item">
            <a [attr.test-id]="testIds.NavToolbarLogout" (click)="onLogout()" transloco="NAV_TOOLBAR.LOGOUT"></a>
          </li>
          <li class="dropdown-menu-note">
            <span>{{ 'NAV_TOOLBAR.LAST_LOGIN' | transloco }}: <span class="uppercase-text">{{ lastLogin | date : lastLoginDateFormat }}</span></span>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>
