<section class="login-area">
  <div class="ms-top-header">
    <ul class="container">
      <li class="ms-logo-area">
        <ms-logo-component></ms-logo-component>
      </li>
    </ul>
  </div>
  <ul class="container">
    <li class="okta-area flexible">
      <div class="container">
        <div id="{{windowLocationId}}"></div>
      </div>
    </li>
  </ul>
</section>
