import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {map} from 'rxjs/operators';
import {mapToNomenclature} from '@matchsource/api-utils';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {Nomenclature} from '@matchsource/models/nomenclature';

@Injectable({
  providedIn: 'root',
})
export class CloseReasonsApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getAll(): Observable<Nomenclature[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSubjectData);

    return this.nomenclaturesControllerService
      .listAllCloseReasons(null, {
        context: context(),
      })
      .pipe(
        map(items => {
          return items.map(item => mapToNomenclature(item));
        })
      );
  }
}
